import React, { useEffect, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
import ImagesService from 'services/image-services';
import CompetitirService from 'services/competitor-service';
import styles from './admin-team-elements-properties.module.less';
import Modal from "../modal/modal";
import competitorService from 'services/competitor-service';
import VenueMainBlock from "../venue-main-block/venue-main-block";
import JErsey from 'components/block-elements/jersey-element/jersey-element';
import { SketchPicker  } from 'react-color';
import ColorPickerButton from "../color-picker-button/colorPickerButton";

const AdminTeamElementsProperties = (props) => {
    const [newImage, setNewImage] = useState(null);
    const [loading, setLoading] = useState(false);

    const [homeTeamJerseyColors, setHomeTeamJerseyColors] = useState({
        competitorId: '',
        tournamentId: '',
        jerseyColor: '',
        stripesColor: '',
        stripesExtraColor: '',
        numberColor: '',
        numberOutlineColor: '',
        isHomeTeam: '',
        isChanged: false
    });

    const [awayTeamJerseyColors, setAwayTeamJerseyColors] = useState({
        competitorId: '',
        tournamentId: '',
        jerseyColor: '',
        stripesColor: '',
        stripesExtraColor: '',
        numberColor: '',
        numberOutlineColor: '',
        isHomeTeam: '',
        isChanged: false
    });

    const [team, setTeam] = useState({
        name: '',
        country: '',
        id: null,
        sport: '',
        displayName: '',
        sportId: '',
        image: '',
        countryCode: '',
        newImageFile: null,
        formation: '',
        newImageId: null,
        venueId: '',
        venueName: '',
        venueCapacity: 0,
        venueCityName: '',
        venueCountry: '',
        venueMapCoordinateLat: '',
        venueMapCoordinateLong: ''
    });



    const [isModalShow, setIsModalShow] = useState(false);
    useEffect(() => {
        hetTeamColors();
        setTeam(props);
    },[])

    const hetTeamColors = async() => {
        await Promise.all([
            getHomeTeamJerseyColors(),
            getAwayTeamJerseyColors()
        ])
    }

    const getHomeTeamJerseyColors = async() => {
        await competitorService.getCompetitorJerseyColorAsync(props.id, true)
            .then(res => {
                setHomeTeamJerseyColors({...homeTeamJerseyColors, 
                    competitorId: res.data.competitorId ?? props.id,
                    isHomeTeam: res.data.isHomeTeam,
                    jerseyColor: res.data.jerseyColor ?? '#000000',
                    numberColor: res.data.numberColor ?? '#E8CB2D',
                    numberOutlineColor: res.data.numberOutlineColor,
                    stripesColor: res.data.stripesColor ?? '#C8242B',
                    stripesExtraColor: res.data.stripesExtraColor ?? '#E8CB2D',
                    tournamentId: res.data.tournamentId ?? 'e:tournament:21'
                })
            }).catch(err => {

            });
    }

    const getAwayTeamJerseyColors = async() => {
        await competitorService.getCompetitorJerseyColorAsync(props.id, false)
            .then(res => {
                setAwayTeamJerseyColors({...awayTeamJerseyColors, 
                    competitorId: res.data.competitorId ?? props.id,
                    isHomeTeam: res.data.isHomeTeam,
                    jerseyColor: res.data.jerseyColor ?? '#FFFFFF',
                    numberColor: res.data.numberColor ?? '#000000',
                    numberOutlineColor: res.data.numberOutlineColor,
                    stripesColor: res.data.stripesColor ?? '#C8242B',
                    stripesExtraColor: res.data.stripesExtraColor ?? '#E8CB2D',
                    tournamentId: res.data.tournamentId ?? 'e:tournament:21'
                })
            }).catch(err => {

            })
    }

    const handleChange = (event) => {
        setTeam({...team, newImageFile: event.target.files });
        setNewImage(URL.createObjectURL(event.target.files[0]))
    }

    const changeHandlerName = (event) => {
        setTeam({...team, name: event.target.value });
    };

    const changeHandlerCountry = (event) => {
        setTeam({...team, country: event.target.value });
    }

    const changeHandlerDisplayName = (event) => {
        setTeam({...team, displayName: event.target.value });
    }

    const changeHandlerFormation = (event) => {
        setTeam({ ...team, formation: event.target.value })
    }

    const uploadTeamImage = async() => {
        let result = null;
        const fd = new FormData();

        fd.append('files', team.newImageFile[0], team.name);
        await ImagesService.UploadNewFile(fd, 3, team.name)
        .then(res => {
            result = res.data;
            setTeam({ ...team, newImageId: res.data });
        }).catch(err => toast.error("Image upload faild"));  
        
        return result;
    }

    const openModal = () => {
        setIsModalShow(true);
    }

    const ccVenue = (newVenue) => {
        setTeam({...team, venueId: newVenue.id, venueName: newVenue.name, venueCapacity:  newVenue.capacity, venueCityName: newVenue.cityName, venueCountry: newVenue.country});
    }

    const handleChangeComplete = (color, event) => {
        setHomeTeamJerseyColors({ ...homeTeamJerseyColors, jerseyColor: color.hex, isChanged: true, isHomeTeam: true });
      };

    const stripesColorHomeChange = (color, event) => {
        setHomeTeamJerseyColors({ ...homeTeamJerseyColors, stripesColor: color.hex, isChanged: true, isHomeTeam: true });
    }

    const stripesExtraColorHomeChange = (color, event) => {
        setHomeTeamJerseyColors({ ...homeTeamJerseyColors, stripesExtraColor: color.hex, isChanged: true, isHomeTeam: true });
    }

    const numberColorHomeChange = (color, event) => {
        setHomeTeamJerseyColors({ ...homeTeamJerseyColors, numberColor: color.hex, isChanged: true, isHomeTeam: true });
    }

    const numberOutlineColorHomeChange = (color, event) => {
        setHomeTeamJerseyColors({ ...homeTeamJerseyColors, numberOutlineColor: color.hex, isChanged: true, isHomeTeam: true });
    }

    const jerseyColorChangeAway = (color, event) => {
        setAwayTeamJerseyColors({ ...awayTeamJerseyColors, jerseyColor: color.hex, isChanged: true });
      };

    const stripesColorAwayChange = (color, event) => {
        setAwayTeamJerseyColors({ ...awayTeamJerseyColors, stripesColor: color.hex, isChanged: true });
    }

    const stripesExtraColorAwayChange = (color, event) => {
        setAwayTeamJerseyColors({ ...awayTeamJerseyColors, stripesExtraColor: color.hex, isChanged: true });
    }

    const numberColorAwayChange = (color, event) => {
        setAwayTeamJerseyColors({ ...awayTeamJerseyColors, numberColor: color.hex, isChanged: true });
    }

    const numberOutlineColorAwayChange = (color, event) => {
        setAwayTeamJerseyColors({ ...awayTeamJerseyColors, numberOutlineColor: color.hex, isChanged: true });
    }

    const saveHomeColors = async() => {
        await competitorService.saveCompetitorJerseyColorAsync(homeTeamJerseyColors)
            .then(res => {
                toast.success('Colors has been updated!')
            }).catch(err => {

            });
    }

    const saveAwayColors = async() => {
        await competitorService.saveCompetitorJerseyColorAsync(awayTeamJerseyColors)
            .then(res => {
                toast.success('Colors has been updated!')
            }).catch(err => {

            });
    }

    const save = async() => {
        setLoading(true);
        const uploadTeam = {
            name: '',
            country: null,
            id: null,
            displayName: null,
            image: '',
            countryCode: null,
            newImageId: null,
            venueId: '',
            formation: ''
        };

        if(team.newImageFile != null) {
            uploadTeam.newImageId = await uploadTeamImage();      
        }
        
        uploadTeam.country = team.country;
        uploadTeam.countryCode = team.countryCode;
        uploadTeam.displayName = team.displayName;
        uploadTeam.id = team.id;
        uploadTeam.formation = team.formation;
        uploadTeam.name = team.name;
        uploadTeam.venueId = team.venueId;

        await CompetitirService.changeCompetitorData(uploadTeam)
        .then(res => {
            toast.success('Data changed successfully!');
            props.refresh();
        }).catch(err => {
            toast.error('Changed Faild!');
        })
        setLoading(false);
    }

    return(
        <>
            <div className={styles.buttonContainer}>
                <button className={styles.closeButton} onClick={props.closeModal}>X</button>
            </div>
            <div className={styles.mainContainer}>
                <div className={styles.childContainer}>
                    <span>Team Id</span>
                    <input
                        name='Team Id'
                        value={team.id}
                        readOnly
                        className={styles.input}
                        type='text'>
                    </input>
                    <span>Team Name</span>
                    <input
                        name='Team Name'
                        value={team.name}
                        onInput={changeHandlerName}
                        className={styles.input}
                        type='text'>
                    </input>
                    <span>Team Country</span>
                    <input
                        name='Team Country'
                        value={team.country}
                        onInput={changeHandlerCountry}
                        className={styles.input}
                        type='text'>
                    </input>
                    <span>Team Display Name</span>
                    <input
                        name='Team DisplayName'
                        value={team.displayName}
                        onInput={changeHandlerDisplayName}
                        className={styles.input}
                        type='text'>
                    </input>
                    <span>Team Sport</span>
                    <input
                        name='Team DisplayName'
                        value={team.sport}
                        readOnly
                        className={styles.input}
                        type='text'>
                    </input>
                    <span>Default formation</span>
                    <input onInput={(e) => changeHandlerFormation(e)} type='text' value={team.formation} name="Formation"></input>
                    <span>Venue</span>
                    <div className={styles.venue} onClick={openModal}>
                        <div>
                            <span>Name: </span> 
                            <span>{team?.venueName}</span> 
                        </div>
                        <div>
                            <span>Capacity: </span> 
                            <span>{team?.venueCapacity}</span> 
                        </div>
                        <div>
                            <span>Country: </span>
                            <span>{team?.venueCountry}, {team?.venueCityName}</span> 
                        </div>
                    </div>
                    <span>Team Sport Id</span>
                    <input
                        name='Team DisplayName'
                        value={team.sportId}
                        readOnly
                        className={styles.input}
                        type='text'>
                    </input>
                    <span>Image</span>
                    <img className={styles.modalTeamImage} src={newImage ?? team.image}></img>
                    <input accept="image/*" className={styles.inputFile} type="file" onChange={handleChange} />

                    {team.sport == 'Ishockey' && <div style={{position: 'relative', marginTop: 30}}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div>
                                <ColorPickerButton id={1} text={'Home Jersey Color'} color={homeTeamJerseyColors.jerseyColor ?? '#000000'} handleChangeComplete={handleChangeComplete}/>
                                <ColorPickerButton id={2} text={'Home Stripes Color'} color={homeTeamJerseyColors.stripesColor ?? '#C8242B'} handleChangeComplete={stripesColorHomeChange}/>
                                <ColorPickerButton id={3} text={'Home Stripes Extra Color'} color={homeTeamJerseyColors.stripesExtraColor ?? '#E8CB2D'} handleChangeComplete={stripesExtraColorHomeChange}/>
                                <ColorPickerButton id={4} text={'Home Number Color'} color={homeTeamJerseyColors.numberColor ?? '#E8CB2D'} handleChangeComplete={numberColorHomeChange}/>
                                <ColorPickerButton id={5} text={'Home Number Outline Color'} color={homeTeamJerseyColors.numberOutlineColor ?? '#ffffff'} handleChangeComplete={numberOutlineColorHomeChange}/>
                            </div>
                            <div>
                                <JErsey jerseyColor={homeTeamJerseyColors.jerseyColor}
                                    jerseyStrypeColor={homeTeamJerseyColors.stripesColor}
                                    jerseyStrypeExtraColor={homeTeamJerseyColors.stripesExtraColor}
                                    width='115' 
                                    height='115' 
                                    peayerNumber='99' 
                                    peayerFullName='John Smith'
                                    jerseyScoreColor={homeTeamJerseyColors.numberColor}
                                    jerseyScoreBorderColor={homeTeamJerseyColors.numberOutlineColor}/>
                            </div>
                        </div>
                        <button onClick={saveHomeColors} style={{width: '100%', marginTop: 14}}>Save home colors</button>
                    </div>}

                    {team.sport == 'Ishockey' && <div style={{position: 'relative', marginTop: 30}}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div>
                                <ColorPickerButton id={6} text={'Away Jersey Color'} color={awayTeamJerseyColors.jerseyColor ?? '#FFFFFF'} handleChangeComplete={jerseyColorChangeAway}/>
                                <ColorPickerButton id={7} text={'Away Stripes Color'} color={awayTeamJerseyColors.stripesColor ?? '#C8242B'} handleChangeComplete={stripesColorAwayChange}/>
                                <ColorPickerButton id={8} text={'Away Stripes Extra Color'} color={awayTeamJerseyColors.stripesExtraColor ?? '#E8CB2D'} handleChangeComplete={stripesExtraColorAwayChange}/>
                                <ColorPickerButton id={9} text={'Away Number Color'} color={awayTeamJerseyColors.numberColor ?? '#000000'} handleChangeComplete={numberColorAwayChange}/>
                                <ColorPickerButton id={10} text={'Away Number Outline Color'} color={awayTeamJerseyColors.numberOutlineColor ?? '#ffffff'} handleChangeComplete={numberOutlineColorAwayChange}/>
                            </div>
                            <div>
                                <JErsey jerseyColor={awayTeamJerseyColors.jerseyColor}
                                    jerseyStrypeColor={awayTeamJerseyColors.stripesColor}
                                    jerseyStrypeExtraColor={awayTeamJerseyColors.stripesExtraColor}
                                    width='115' 
                                    height='115' 
                                    peayerNumber='99' 
                                    peayerFullName='John Smith'
                                    jerseyScoreColor={awayTeamJerseyColors.numberColor}
                                    jerseyScoreBorderColor={awayTeamJerseyColors.numberOutlineColor}/>
                            </div>
                        </div>
                        <button onClick={saveAwayColors} style={{width: '100%', marginTop: 14}}>Save away colors</button>
                    </div>}

                    {!loading && <button style={{marginBottom: 150}} disabled={loading} onClick={save} className={styles.saveButton}>Save</button>}
                    <Toaster position='top-left' reverseOrder={true} />
                </div>
            {isModalShow && <Modal zIndex={15} setIsOpen={() => setIsModalShow(false)}>
                <VenueMainBlock closeModel={() => setIsModalShow(false)} setVenue={(val) => ccVenue(val)}  isFroIdReturn={true}/>
            </Modal>}
            </div>
        </>
    )
}

export default AdminTeamElementsProperties;